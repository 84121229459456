<template>
  <div class="loginDialogBox">
    <img
      @click="backHome"
      class="logo cursor"
      src="../../assets/img/newLogo.png"
      alt
      srcset
    />
    <div v-show="isSignUp === 1">
      <div class="tabs">
        <el-tabs v-model="activeLabel">
          <el-tab-pane label="手机注册" name="phone" class="codeLogin">
            <el-form
              :label-position="'top'"
              :model="phoneForm"
              ref="phoneForm"
              :rules="phoneRules"
            >
              <el-form-item label prop="phone">
                <el-input
                  placeholder="请输入手机号"
                  v-model="phoneForm.phone"
                  class="input-with-select"
                  type="number"
                >
                  <el-select
                    v-model="phoneForm.areaCode"
                    slot="prepend"
                    popper-class="registerAreaCodeList"
                    filterable
                    :placeholder="$t('pleaseSelect')"
                    :filter-method="areaCodeFilter"
                  >
                    <el-option
                      v-for="(item, index) in areaCodeList"
                      :key="item.area_code + index + ''"
                      :value="item.area_code"
                      :title="item.country | areacodeTitleFormat(LOCALE)"
                    >
                      <p class="area_code">{{ item.area_code }}</p>
                      <p class="country textOverflow">
                        {{ item.country | areacodeAddressFormat(LOCALE) }}
                      </p>
                    </el-option>
                  </el-select>
                </el-input>
              </el-form-item>
              <el-form-item prop="code">
                <el-input
                  style="width: 200px"
                  placeholder="请输入验证码"
                  type="text"
                  v-model="phoneForm.code"
                  @keyup.enter.native="sign"
                  autocomplete="off"
                  nowritten
                ></el-input>
                <el-button
                  :disabled="codeBtnDisabled"
                  class="fr getCodeBtn"
                  @click="getCode"
                  >{{ codeText }}</el-button
                >
                <div class="custom_error_tip" v-show="phoneErrorShow">
                  {{ $t("invalidphoneAccount") }}
                </div>
                <div class="custom_error_tip" v-show="phoneDisabledShow">
                  {{ $t("accountDisabledTip") }}
                </div>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="邮箱注册" name="email" class="passwordLogin">
            <el-form
              :model="emailForm"
              :rules="emailRules"
              ref="emailForm"
              :label-position="'top'"
              label-width="80px"
            >
              <el-form-item label prop="email">
                <el-input
                  placeholder="请输入邮箱"
                  v-model="emailForm.email"
                  nowritten
                ></el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input
                  placeholder="设置密码（长度为6-16位）"
                  type="password"
                  v-model="emailForm.password"
                  nowritten
                  :show-password="true"
                  autocomplete="off"
                  @keyup.enter.native="sign"
                ></el-input>
              </el-form-item>
              <el-form-item prop="passwordAgain">
                <el-input
                  placeholder="确认密码"
                  type="password"
                  v-model="emailForm.passwordAgain"
                  nowritten
                  :show-password="true"
                  autocomplete="off"
                  @keyup.enter.native="sign"
                ></el-input>
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div></div>
      <div class="text_link">
        <div>
          <el-button
            type="primary"
            class="loginBut"
            size="small"
            @click="sign"
            :loading="signLoading"
            >立即注册</el-button
          >
        </div>
        <div class="forgetPassword">
          已有账号，去
          <router-link to="/sign">登录</router-link>
        </div>
        <div class="toReigster">
          <span class="registerText">点击立即注册即代表同意用户服务协议</span>
        </div>
      </div>
    </div>
    <div v-show="isSignUp === 2" class="SignUpBox">
      <div class="SignUpBoxContent">
        <i class="el-icon-success SignUpIcon"></i>
        <div style="margin-left: 10px; padding-top: 8px">
          <div class="tip_header">注册成功</div>
          <div class="goLogin" @click="goLogin">立即登录</div>
        </div>
      </div>
    </div>
    <div v-show="isSignUp === 3" class="SignUpBox">
      <div class="tip_header">激活链接已发送至</div>
      <EmailLogin
        class="mailLogin"
        :email="emailForm.email"
        :color="'#4A5A81'"
      ></EmailLogin>
      <div class="small">
        <span class="dangerColor">*</span>请在24小时内点击激活链接
      </div>
    </div>
    <p class="botTxt clearfix"></p>
  </div>
</template>

<script>
import { LCstore } from "~/baseUtils/storage";
import {
  validateTelephone,
  validateEmail,
  validatePassWord,
} from "~/baseUtils/validate";
import { areaCodeList } from "~/baseUtils/AddressConfig";
import EmailLogin from "~/baseComponents/EmailLogin";
export default {
  metaInfo: {
    title: "注册",
  },
  props: {
    isInquiry: {
      type: Boolean,
      default: false,
    },
    inquiryParams: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      codeText: "获取验证码",
      activeLabel: "phone",
      emailForm: {
        email: "",
        password: "",
        passwordAgain: "",
      },
      sevenChecked: false,
      areaCodeList,
      areaCodeListCopy: [].concat(areaCodeList),
      cetshow: false,
      signLoading: false,
      emailErrorShow: false,
      phoneErrorShow: false,
      emailDisabledShow: false,
      phoneDisabledShow: false,
      phoneForm: {
        phone: "",
        code: "",
        areaCode: "+86",
      },
      emailRules: {
        email: {
          validator: async (rule, value, callback) => {
            if (value == "") {
              callback(new Error(" "));
            } else if (!validateEmail(value)) {
              callback(new Error("邮箱格式错误"));
            } else {
              try {
                let result = await this.$store.dispatch(
                  "API_user/user_checkIdentifier",
                  { identifier: value, type: 1 ,source:this.PJSource}
                );
                if (result.success) {
                  callback();
                } else {
                  if (result.errorcode == 1000) {
                    callback(new Error("该邮箱已注册"));
                  }
                  callback(new Error(" "));
                }
              } catch (e) {
                callback(new Error(" "));
                this.$seTip();
              }
            }
          },
          trigger: "blur",
        },
        password: {
          validator: (rule, value, callback) => {
            if (value == "") {
              callback(new Error(" "));
            } else if (!validatePassWord(value)) {
              callback(new Error("请输入6-16位密码，不可包含特殊字符"));
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
        passwordAgain: {
          validator: (rule, value, callback) => {
            if (this.emailForm.password == "") {
              callback();
            } else if (this.emailForm.password != "" && value == "") {
              callback(new Error(" "));
            } else if (
              this.emailForm.password != "" &&
              value !== this.emailForm.password
            ) {
              callback(new Error("密码不一致"));
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
      },
      phoneRules: {
        phone: {
          validator: async (rule, value, callback) => {
            if (value == "") {
              callback(new Error(" "));
            } else if (
              this.phoneForm.areaCode == "+86" &&
              !validateTelephone(value)
            ) {
              callback(new Error("手机号格式错误"));
            } else {
              try {
                let result = await this.$store.dispatch(
                  "API_user/user_checkIdentifier",
                  { identifier: `${this.phoneForm.areaCode}${value}`, type: 2,source:this.PJSource }
                );
                if (result.success) {
                  callback();
                } else {
                  if (result.errorcode == 1000) {
                    callback(new Error("手机号已注册"));
                  }
                  callback(new Error(" "));
                }
              } catch (e) {
                callback(new Error(" "));
                this.$seTip();
              }
            }
          },
          trigger: "blur",
        },
        code: {
          validator: async (rule, value, callback) => {
            if (value == "") {
              callback(new Error(" "));
            } else {
              if (this.checkCode) {
                let result = await this.$store.dispatch(
                  "baseStore/checkVerifyCode",
                  {
                    area_code: this.phoneForm.areaCode.replace("+", ""),
                    mobile: `${this.phoneForm.phone}`,
                    ctype: 1,
                    code: this.phoneForm.code,
                  }
                );
                this.checkCode = false;
                if (result.success) {
                  if (result.data) {
                    callback();
                  } else {
                    callback(new Error("验证码错误"));
                  }
                } else {
                  callback(new Error(" "));
                  this.$seTip();
                }
              } else {
                callback();
              }
            }
          },
          trigger: "blur",
        },
      },
      codeBtnDisabled: false,
      checkCode: false,
      isSignUp: 1,
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {
    emailForm: {
      deep: true,
      handler() {
        this.clearErrorTip();
      },
    },
    phoneForm: {
      deep: true,
      handler() {
        this.clearErrorTip();
      },
    },
  },
  methods: {
    async getCode() {
      if (this.phoneForm.phone == "") {
        this.$message.warning("请输入手机号");
        return;
      }
      if (parseInt(this.codeText) == this.codeText) {
        return;
      }
      try {
        let result = { success: true };
        await this.$store.dispatch("baseStore/getVerifyCode", {
          area_code: this.phoneForm.areaCode.replace("+", ""),
          mobile: `${this.phoneForm.phone}`,
          ctype: 1,
        });
        if (result.success) {
          this.$message.success("验证码发送成功");
          const TIME_COUNT = 60;
          if (!this.timer) {
            this.codeText = TIME_COUNT;
            this.codeBtnDisabled = true;
            this.timer = setInterval(() => {
              if (this.codeText > 0 && this.codeText <= TIME_COUNT) {
                this.codeText--;
              } else {
                this.codeText = "获取验证码";
                clearInterval(this.timer);
                this.timer = null;
                this.codeBtnDisabled = false;
              }
            }, 1000);
          }
        } else {
          this.$seTip();
        }
      } catch (e) {
        this.$seTip();
      }
    },
    backHome() {
      this.$router.push("/");
    },
    areaCodeFilter(value) {
      if (value) {
        this.areaCodeList = this.areaCodeListCopy
          .filter((item) => {
            return item.area_code.indexOf(value) == 1;
          })
          .sort((a, b) => {
            return parseInt(a.area_code) - parseInt(b.area_code);
          });
      } else {
        this.areaCodeList = this.areaCodeListCopy;
      }
    },
    sign() {
      let activeLabel = this.activeLabel;
      this.checkCode = true;
      this[`${activeLabel}ErrorShow`] = false;
      this[`${activeLabel}DisabledShow`] = false;
      this.$refs[`${activeLabel}Form`].validate(async (valid) => {
        if (valid) {
          this.signLoading = true;
          let isPhone = activeLabel == "phone";
          let params = {
            source: this.PJSource,
          };
          if (isPhone) {
            params.area_code = this.phoneForm.areaCode;
            params.mobile = `${this.phoneForm.phone}`;
            params.login_type = 2;
          } else {
            params.email = this.emailForm.email;
            params.password = this.emailForm.password;
          }
     

          try {
            let result = await this.$store.dispatch(
              "API_user/user_Register",
              params
            );
            if (result.success) {
            
              if (isPhone) {
                this.isSignUp = 2;
              } else {
                this.isSignUp = 3;
              }
           
            } else {
             
            }
            this.signLoading = false;
          } catch (e) {
            this.signLoading = false;
            this.$seTip();
          }
        }
      });
    },
    async getNewActiveLink() {
      try {
        let result = await this.$store.dispatch(
          "API_user/user_getActiveMailRegain",
          {
            email: this.emailForm.email,
          }
        );
        if (result.success) {
          //this.$message.success(this.$t(""));
          this.$osTip();
          this.cetshow = false;
        } else {
          this.$seTip();
        }
      } catch (e) {
        this.$seTip();
      }
    },
    clearErrorTip() {
      this.emailErrorShow = false;
      this.phoneErrorShow = false;
      this.emailDisabledShow = false;
      this.phoneDisabledShow = false;
      this.cetshow = false;
    },
    toRegister() {
      this.$GLOBALEVENT.$emit("CLOSEGLOBALLOGIN");
      this.$router.push("/signup");
    },
    goLogin() {
      this.$router.push("/sign");
    },
  },
  components: { EmailLogin },
};
</script>

<style scoped lang="less">
.loginDialogBox {
  width: 400px;
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.14);
  border: 1px solid #979797;
  border-radius: 10px;
  padding-top: 24px;
  padding-bottom: 40px;
  margin: 0 auto;
  min-height: 405px;
  .SignUpBox {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 200px;
    .SignUpBoxContent {
      display: flex;
      .SignUpIcon {
        color: #4A5A81;
        font-size: 60px;
      }
    }
    .tip_header {
      font-weight: 600;
      font-size: 14px;
    }
    .goLogin {
      margin-top: 8px;
      cursor: pointer;
      color: #4A5A81;
      font-weight: 600;
    }
    .small {
      font-size: 14px;
    }
  }
  .logo {
    height: 48px;
    margin: 0 auto;
    display: block;
  }
  .tabs {
    padding: 20px;
    padding-bottom: 0px;
    .el-tabs {
      width: 325px;
      margin: 0 auto;
    }
  }
  .emailLogin {
    padding: 0 16px;
    padding-top: 24px;
    padding-bottom: 6px;
  }
  .botTxt {
    padding: 0 50px;
    .fl {
      color: #0083f6;
      cursor: pointer;
    }
    .fr {
      color: #999;
      cursor: pointer;
    }
  }
  .loginBut {
    width: 325px;
    display: block;
    height: 40px;
    margin: 0px auto 12px;
    background: #4A5A81;
    border: 0 none;
    font-size: 16px;
  }
  .registerText {
    color: #4A5A81;
    cursor: pointer;
  }
  .text_link {
    width: 325px;
    font-size: 14px;
    margin: 0 auto 24px;
    height: 22px;
    .forgetPassword {
      float: left;
      a {
        color: #4A5A81;
      }
    }
    .toReigster {
      margin-top: 2px;
      float: right;
      font-size: 12px;
      span {
        color: #909399;
      }
    }
  }
  .getCodeBtn {
    width: 112px;
    color: #4A5A81;
    &:hover,
    &:focus {
      color: #4A5A81;
      // border-color: #ffb9bb;
      // background-color: #fee;
    }
  }
  /deep/ .el-checkbox {
    .el-checkbox__input.is-checked + .el-checkbox__label {
      color: #4A5A81;
    }
    &.is-checked {
      .el-checkbox__inner {
        background-color: #4A5A81;
        border-color: #4A5A81;
      }
    }
  }
}

.loginDialogBox /deep/ .el-tabs__header {
  .el-tabs__active-bar {
    background-color: #4A5A81;
  }
  .el-tabs__item {
    font-size: 16px;
    color: #7d8695;
    &:hover {
      color: #4A5A81;
    }
    &.is-active {
      color: #4A5A81;
    }
  }
  margin: 0;
}
.loginDialogBox /deep/ .el-tabs__content {
  margin-top: 15px;
}
.loginDialogBox /deep/ .el-tabs__nav {
  transform: translateX(78.5px) !important;
}
.loginDialogBox.zh /deep/ .el-tabs__nav {
  transform: translateX(104px) !important;
}
</style>
